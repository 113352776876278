<template>
    <el-container class="chat-box">
        <chat-box v-if="chatStore.activeChat" :chat="chatStore.activeChat"></chat-box>
    </el-container>
</template>

<script>
import ChatBox from "../components/chat/ChatBox.vue";

export default {
    name: "chat",
    components: {
        ChatBox
    },
    data () {
        return {
        }
    },
    methods: {

        loadFriend (friendId) {
            // 获取对方最新信息
            this.$http({
                url: `/user/find/${friendId}`,
                method: 'get'
            }).then((friend) => {
                this.friend = friend;
                this.$store.commit("updateChatFromFriend", friend);
                this.$store.commit("updateFriend", friend);
                let chat = {
                    type: 'PRIVATE',
                    targetId: friendId,
                    showName: friendId,
                    headImage: null,
                };
                this.$store.commit("openChat", chat);
                this.$store.commit("activeChat", 0);
            })
        },
        onAddFriend (user) {
            this.$http({
                url: "/friend/add",
                method: "post",
                params: {
                    friendId: user
                }
            }).then((data) => {
                console.log('this.$store.state.chatStore', this.$store.state.chatStore);
                // this.$message.success("添加成功，对方已成为您的好友");
                let friend = {
                    id: user,
                    nickName: user.nickName,
                    headImage: user.headImage,
                    online: user.online
                }
                this.$store.commit("addFriend", friend);
                this.loadFriend(user);
            })
        },
    },
    computed: {
        chatStore () {
            function getQueryParam (param) {
                // 获取当前页面的查询字符串
                var queryString = window.location.hash;
                if (queryString.split('?').length > 1) {
                    // 去掉开头的问号
                    queryString = queryString.split('?')[1]
                    // 将查询字符串分割成键值对
                    var params = queryString.split('&');
                    // 遍历键值对数组
                    for (var i = 0; i < params.length; i++) {
                        var pair = params[i].split('=');
                        // 如果找到匹配的参数名，返回对应的值
                        if (pair[0] === param) {
                            return decodeURIComponent(pair[1]);
                        }
                    }
                }
                // 如果没有找到匹配的参数名，返回null
                return null;
            }
            var sellerUserId = getQueryParam('sellerUserId');

            //拿到ID，添加好友，进行聊天
            if (sellerUserId) {
                this.onAddFriend(sellerUserId);
            }
            // this.onActiveItem(0);
            return this.$store.state.chatStore;
        },

        loading () {
            return this.chatStore.loadingGroupMsg || this.chatStore.loadingPrivateMsg
        },

    }
}
</script>

<style lang="scss">
.chat-page {
    .chat-list-box {
        display: flex;
        flex-direction: column;
        border: #dddddd solid 1px;
        background: white;

        .chat-list-header {
            padding: 3px 8px;
            line-height: 50px;
            border-bottom: 1px #ddd solid;

            .el-input__inner {
                border-radius: 10px !important;
                background-color: #F8F8F8;
            }

        }

        .chat-list-loadding {
            height: 50px;
            background-color: #eee;

            .chat-loading-box {
                height: 100%;
            }
        }

        .chat-list-items {
            flex: 1;
            background: #F8F8F8;
            margin: 0 3px;
        }
    }
}
</style>